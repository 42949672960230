export function ready(fn) {
  if (document.attachEvent ? document.readyState === "complete" : document.readyState !== "loading") {
    fn();
  } else {
    document.addEventListener('DOMContentLoaded', fn);
  }
}

export function addEventListener(event, element, callback) {
  if (element.addEventListener)
    element.addEventListener(event, callback, false);
  else if (element.attachEvent) {
    element.attachEvent(`on${event}`, callback);
  }
}

export function addEventListeners(event, elements, callback) {
  [].forEach.call(elements, (el) => addEventListener(event, el, callback));
}