import { addEventListener } from 'utils/events';
import { addClass, removeClass, toggleClass } from 'utils/elements';

export default class TopLink {
  constructor() {
    let toplink = document.getElementsByClassName('js-toplink')[0];
    addEventListener('click', toplink, function(e) {
      e.preventDefault();
      document.getElementsByTagName('body')[0].scrollIntoView({ 
        behavior: 'smooth' 
      });
    });
  }
}
