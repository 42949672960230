import PhotoSwipe from "photoswipe/dist/photoswipe.js";
import PhotoSwipeUI_Default from "photoswipe/dist/photoswipe-ui-default.js";
import { addEventListeners } from "utils/events";

export default class SwipeGallery {
  constructor(selector) {
    this.parent = document.getElementsByClassName(selector)[0];
    this.assets = this.parent.getElementsByClassName("asset");
    this.pswpElement = document.querySelectorAll(".pswp")[0];
    this.data = [];
    if (this.parent && this.pswpElement) {
      this.initialize();
    }
  }

  initialize() {
    for (let asset of this.assets) {
      this.processAsset(asset);
    }
    addEventListeners("click", this.assets, el => {
      let index = [].indexOf.call(this.assets, el.currentTarget);
      let options = {
        index: index
      };
      this.openGallery(options);
    });
  }

  openGallery(options) {
    this.gallery = new PhotoSwipe(
      this.pswpElement,
      PhotoSwipeUI_Default,
      this.data,
      options
    );
    this.gallery.init();
  }

  processAsset(asset) {
    const image = asset.getElementsByClassName("image")[0];
    let assetData = {
      src: asset.getAttribute("data-img-lrg"),
      msrc: asset.getAttribute("data-img-sml"),
      w: asset.getAttribute("data-width"),
      h: asset.getAttribute("data-height"),
      title: asset.getAttribute("data-title")
    };
    this.data.push(assetData);
  }
}
