import cookie from 'cookie';
import { addClass, removeClass } from 'utils/elements';
import { addEventListener } from 'utils/events';

function cookieExists() {
  return (document.cookie && document.cookie.includes) ? document.cookie.includes('allow_cookies=accepted') : false;
}

export default function initCookiePolicy() {
  const expiresDate = new Date(new Date().setFullYear(new Date().getFullYear() + 1));
  const allowCookie = cookie.serialize('allow_cookies', 'accepted', { expires: expiresDate, path: '/' });

  if (cookieExists() !== true) {
    const cookiePolicyBanner = document.getElementById('cookie-policy');
    const cookiePolicyDismiss = document.getElementsByClassName('js-dismiss-cookie')[0];
    addClass(cookiePolicyBanner, 'is-visible');

    addEventListener('click', cookiePolicyDismiss, function(e) {
      e.preventDefault();
      e.stopPropagation();
      document.cookie = allowCookie;
      removeClass(cookiePolicyBanner, 'is-visible')
    });
  }
}
