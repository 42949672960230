export default class Sticky {
  constructor(selector = null, anchor = null) {
    this.selector = selector || "js-sticky";
    this.anchor = anchor || this.selector;
    this.initialize();
  }

  initialize() {
    this.sticky = document.getElementsByClassName(this.selector)[0];
    this.stickyAnchor = document.getElementsByClassName(this.anchor)[0];
    this.stickyOfset = this.stickyAnchor.offsetTop;
    if (this.sticky) {
      window.onscroll = () => {
        this.stick();
      };
    }
  }

  stick() {
    if (window.pageYOffset >= this.stickyOfset) {
      this.sticky.classList.add("sticky");
    } else if (this.sticky.classList.contains("sticky")) {
      this.sticky.classList.remove("sticky");
    }
  }
}
