import LittleBird from "utils/little-bird";
import { ready } from "utils/events";
import Carousel from "modules/carousel";
import Sticky from "modules/sticky";
import SwipeGallery from "modules/swipe-gallery";
import ProfileBrowser from "modules/profile-browser";
import MobileNav from "modules/mobile-nav";
import ScrollTo from "modules/scroll-to";
import TopLink from "modules/top-link";
import InfiniteScroll from "infinite-scroll";
import initCookiePolicy from "modules/cookie-policy";

const modules = {
  common: {
    initialize: () => {
      new MobileNav();
      new TopLink();
      initCookiePolicy();
    },
  },
  homepages: {
    show: () => {
      new Carousel();
    },
  },
  categories: {
    index: () => {
      new Sticky("project-categories", "page--projects");
    },
  },
  media_articles: {
    books: () => {
      new Sticky("page-sections", "page--press");
      new Carousel();
    },
    shop: () => {
      new Sticky("page-sections", "page--press");
      new Carousel();
    },
    writing: () => {
      new Sticky("page-sections", "page--press");
      var infScroll = new InfiniteScroll(".js-scroll", {
        path: ".pagination__next",
        append: ".article",
        history: false,
      });
    },
    press: () => {
      new Sticky("page-sections", "page--press");
      var infScroll = new InfiniteScroll(".js-scroll", {
        path: ".pagination__next",
        append: ".press",
        history: false,
      });
    },
  },
  pages: {
    events: () => {
      new Sticky("page-sections", "page--events");
      new ScrollTo();
    },
    show: () => {
      new Sticky("page-sections", "page--about--with-subnav");
      new Carousel();
    },
    about: () => {
      new Sticky("page-sections", "page--about--with-subnav");
      new Carousel();
    },
  },
  projects: {
    index: () => {
      new Sticky("project-categories", "page--projects");
    },
    show: () => {
      new SwipeGallery("project-assets");
    },
  },
  users: {
    index: () => {
      new ProfileBrowser();
    },
  },
};
ready(() => {
  window.App = new LittleBird(modules);
});
