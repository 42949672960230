import Swiper from 'swiper';

export default class Carousel {
  constructor() {
    this.count = document.getElementsByClassName('slide-count__active')[0];
    var mySwiper = new Swiper ('.js-carousel', {
      speed: 700,
      loop: true,

      effect: 'fade',
      fadeEffect: {
        crossFade: false
      },
    
      navigation: {
        nextEl: '.glide__arrow--right',
        prevEl: '.glide__arrow--left',
      },

      autoplay: {
        delay: 5000
      }
    });
    mySwiper.on('transitionStart', () => {
      const count = this.count;
      count.innerText =(mySwiper.activeIndex - 1) % (mySwiper.slides.length - 2) + 1;
    })
  }
}
