function is_null(value) {
  return typeof value === 'undefined' || value === null;
}

export default class LittleBird {
  constructor(props) {
    this.props = props;
    this.initialize();
  }

  initialize() {
    const { props } = this,
          base = document.querySelector((props && props.base) ? props.base : 'body'),
          controller = base.getAttribute('data-controller'),
          action = base.getAttribute('data-action');
    this.execute('common', 'initialize');
    if (is_null(controller) || is_null(action)) {
      console.warn('LittleBird: you need to set controller and action names on the document base');
    } else {
      this.execute(controller, 'initialize');
      this.execute(controller, action);
    }
  }

  execute(controller, action) {
    const { props } = this;
    if (props[controller]) {
      action = props[controller][action];
      if (typeof action === 'function') action();
    }
  }
}

