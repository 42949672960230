export function isNodeList(nodes) {
  var stringRepr = Object.prototype.toString.call(nodes);

  return typeof nodes === 'object' &&
      /^\[object (HTMLCollection|NodeList|Object)\]$/.test(stringRepr) &&
      (typeof nodes.length === 'number') &&
      (nodes.length === 0 || (typeof nodes[0] === "object" && nodes[0].nodeType > 0));
}

export function addClass(element, className) {
  element.className += ` ${className}`;
}

export function removeClass(element, className) {
  if (isNodeList(element)) {
    for (let item of element) {
      item.className = item.className.replace(` ${className}`, '');
    }
  } else {
    element.className = element.className.replace(` ${className}`, '');
  }
}

export function toggleClass(element, className) {
  if (element.classList.contains(className)) {
    removeClass(element, className);
  } else {
    addClass(element, className);
  }
}

export function hasClass(element, className) {
  return element.classList.contains(className);
}
