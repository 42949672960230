import { addEventListener, addEventListeners }  from 'utils/events';
import { removeClass, addClass, hasClass }  from 'utils/elements';

export default class ProfileBrowser {
  constructor(selector = null, profile = null) {
    this.modal = document.getElementsByClassName('modal')[0]
    this.modalContainer = this.modal.getElementsByClassName('modal-container')[0]
    this.modalContent =  this.modal.getElementsByClassName('modal-content')[0]
    this.initialize();
  }

  initialize() {
    const browser = document.getElementsByClassName('js-profile-browser')[0],
          profiles = browser.getElementsByClassName('js-profile'),
          modalBg = this.modal.getElementsByClassName('modal-bg')[0],
          modalClose = this.modal.getElementsByClassName('js-modal-close')[0];

    addEventListeners('click', profiles, (e) => {
      let profile = e.currentTarget;
      this.openModal(profile.innerHTML);
    });
    addEventListeners('click', [modalClose, modalBg], (e) => {
      e.preventDefault();
      let profile = e.currentTarget;
      this.closeModal();
    });
  }

  openModal(content) {
    this.modalContent.innerHTML = content;
    let person = this.modalContent.getElementsByClassName('person-wrap')[0];
    if (person) {
      this.modalContainer.style.height = `${person.offsetHeight}px`
    }
    addClass(this.modal, 'is-open');
  }

  closeModal() {
    removeClass(this.modal, 'is-open');
  }
}
