import { addEventListener } from 'utils/events';
import { addClass, removeClass, toggleClass } from 'utils/elements';

export default class MobileNav {
  constructor(selector = null, anchor = null) {
    this.toggler = document.getElementsByClassName('app-hamburger')[0];
    this.app = document.getElementsByClassName('app')[0];

    addEventListener('click', this.toggler, (e) => {
      e.preventDefault();
      e.stopPropagation();
      toggleClass(this.app, 'nav-is-open');
    });
  }
}
