import { addEventListeners } from "utils/events";

export default class ScrollTo {
  constructor() {
    this.links = document.getElementsByClassName("js-scroll-to");
    addEventListeners("click", this.links, (e) => {
      e.preventDefault();
      let parts = e.currentTarget.href.split("#"),
        scrollTo = document.getElementById(parts[parts.length - 1]);
      scrollTo.scrollIntoView({
        behavior: "smooth",
      });
    });
  }
}
